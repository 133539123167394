<script>
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';

export default {
    components: { Carousel, Slide },
    data() {
        return {
            projectItem: {
                percent: 50,
            },
            tabActive: 1,
            player: null,
        };
    },
    computed: {
        ...mapGetters({
            DetailProject: 'project/DetailProject',
            FinancialReport: 'project/FinancialReport',
            ElectronicContract: 'project/ElectronicContract',
        }),
        videoList() {
            const { list } = this.DetailProject;
            if (list && list.project_videos) {
                return list.project_videos.split(',');
            }
            return null;
        },
    },
    methods: {
        TruncateToDecimals(num, dec = 2) {
            // eslint-disable-next-line global-require
            const numeral = require('numeral');
            // eslint-disable-next-line no-restricted-properties
            const calcDec = Math.pow(10, dec);
            const newNum = Math.trunc(num * calcDec) / calcDec;
            const d = newNum.toString().split('.');
            return `${numeral(d[0]).format(0, 0)}${d[1] ? `.${d[1]}` : ''}`;
        },
        loadVideo(idx) {
            const self = this;
            //   function onPlayerReady(event) {
            //     event.target.playVideo();
            //   }
            // eslint-disable-next-line no-unused-vars

            function setupPlayer() {
                window.YT.ready(() => {
                    self.player = new window.YT.Player(
                        `player-${self.videoList[idx]}`,
                        {
                            width: '100%',
                            height: '100%',
                            videoId: self.videoList[idx],
                            events: {
                                //   onReady: onPlayerReady
                            },
                            playerVars: {
                                playsinline: 0,
                                controls: 1,
                                disablekb: 1,
                                fs: 1,
                                iv_load_policy: 2,
                                modestbranding: 0,
                                hl: 'vi',
                                rel: 0,
                                showinfo: 0,
                                wmode: 'opaque',
                                origin: 'http://localhost:8080',
                            },
                        },
                    );
                });
            }

            (function loadYoutubeIFrameApiScript() {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';

                const firstScriptTag =
                    document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                tag.onload = setupPlayer;
            })();
        },
        onChangeVideo(videoIndex) {
            if (this.player) {
                this.player.stopVideo();
                // this.player = null;
            }
            this.loadVideo(videoIndex);
        },
    },
    watch: {
        DetailProject: {
            handler(newVal) {
                if (newVal) {
                    this.$store.dispatch(
                        'project/get_financialReport',
                        this.DetailProject.list.project_id,
                    );
                    this.$store.dispatch(
                        'project/get_electronicContract',
                        this.DetailProject.list.project_id,
                    );
                    this.loadVideo(0);
                }
            },
        },
        tabActive: {
            handler() {
                if (this.tabActive === 2) {
                    this.loadVideo(0);
                } else if (this.player) {
                    this.player.stopVideo();
                }
            },
        },
    },
    created() {
        if (this.$route.params.id) {
            this.$store.dispatch(
                'project/get_detailsProject',
                this.$route.params.id,
            );
        }
    },
    mounted() {
        if (this.videoList) {
            if (this.player) {
                this.player.stopVideo();
            }
            this.loadVideo(0);
        }
    },
};
</script>
<template>
    <div class="h-100 w-100 mb-5">
        <b-container fluid class="p-0 pif-banner">
            <div class="baner-content">
                <h3
                    v-if="DetailProject.list && DetailProject.list.project_name"
                >
                    {{
                        DetailProject.list && DetailProject.list.project_name
                            ? DetailProject.list.project_name
                            : 'Chưa có thông tin'
                    }}
                </h3>
                <p
                    v-if="
                        DetailProject.list && DetailProject.list.project_website
                    "
                >
                    Website:
                    <a
                        :href="
                            DetailProject.list &&
                            DetailProject.list.project_website
                                ? DetailProject.list.project_website
                                : ''
                        "
                        target="_blank"
                        >{{
                            DetailProject.list &&
                            DetailProject.list.project_website
                                ? DetailProject.list.project_website
                                : 'Chưa có thông tin'
                        }}</a
                    >
                </p>
                <p
                    v-if="
                        DetailProject.list &&
                        DetailProject.list.inves_start_date
                    "
                >
                    Ngày bắt đầu gọi vốn:
                    <span>{{
                        DetailProject.list &&
                        DetailProject.list.inves_start_date
                            ? DetailProject.list.inves_start_date
                            : 'Chưa có thông tin'
                    }}</span>
                </p>
                <p
                    v-if="
                        DetailProject.list && DetailProject.list.inves_end_date
                    "
                >
                    Ngày kết thúc dự kiến:
                    <span>{{
                        DetailProject.list && DetailProject.list.inves_end_date
                            ? DetailProject.list.inves_end_date
                            : 'Chưa có thông tin'
                    }}</span>
                </p>
                <p
                    v-if="
                        DetailProject.list &&
                        DetailProject.list.project_revenue_month
                    "
                >
                    Doanh thu dự kiến:
                    <span>{{
                        DetailProject.list &&
                        DetailProject.list.project_revenue_month
                            ? DetailProject.list.project_revenue_month
                            : 'Chưa có thông tin'
                    }}</span>
                </p>
                <p
                    v-if="
                        DetailProject.list &&
                        DetailProject.list.project_valuation
                    "
                >
                    Định giá doanh nghiệp:
                    <span>{{
                        DetailProject.list &&
                        DetailProject.list.project_valuation
                            ? parseFloat(DetailProject.list.project_valuation)
                                  .toFixed(0)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                              ' VND'
                            : 'Chưa có thông tin'
                    }}</span>
                </p>
                <p>
                    Số vốn gọi:
                    <span>{{
                        DetailProject.list &&
                        DetailProject.list.total_call_project
                            ? parseFloat(DetailProject.list.total_call_project)
                                  .toFixed(0)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                              ' VND'
                            : 'Chưa có thông tin'
                    }}</span>
                </p>
                <div class="progress-content">
                    <div class="progress-text">
                        <p>Đầu tư</p>
                        <p>
                            Kết thúc:
                            {{
                                DetailProject.list &&
                                DetailProject.list.inves_end_date
                                    ? DetailProject.list.inves_end_date
                                    : 'Chưa có thời gian kết thúc'
                            }}
                        </p>
                    </div>
                    <div class="project-process">
                        <div class="loading-progress">
                            <div class="progress-bg">
                                <div
                                    v-if="
                                        DetailProject &&
                                        DetailProject.list &&
                                        DetailProject.list
                                            .total_investment_project &&
                                        DetailProject.list.total_invested
                                    "
                                    class="active-bg"
                                    :style="`width:${
                                        (+DetailProject.list.total_invested /
                                            +DetailProject.list
                                                .total_call_project) *
                                            100 >=
                                        50
                                            ? `calc(${
                                                  (+DetailProject.list
                                                      .total_invested /
                                                      +DetailProject.list
                                                          .total_call_project) *
                                                  100
                                              }%)`
                                            : '-20px'
                                    }`"
                                >
                                    <div class="progress-active">
                                        {{
                                            (
                                                (+DetailProject.list
                                                    .total_invested /
                                                    +DetailProject.list
                                                        .total_call_project) *
                                                100
                                            ).toFixed(2)
                                        }}
                                        %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-text">
                        <p>
                            {{
                                DetailProject.list &&
                                DetailProject.list.project_count_investment
                                    ? TruncateToDecimals(
                                          DetailProject.list
                                              .project_count_investment,
                                      )
                                    : 0
                            }}
                            nhà đầu tư
                        </p>
                        <p>
                            {{
                                DetailProject.list &&
                                DetailProject.list.total_invested
                                    ? TruncateToDecimals(
                                          DetailProject.list.total_invested,
                                      )
                                    : 0
                            }}
                            /
                            {{
                                DetailProject.list &&
                                DetailProject.list.total_call_project
                                    ? TruncateToDecimals(
                                          DetailProject.list.total_call_project,
                                      )
                                    : 0
                            }}
                        </p>
                    </div>
                </div>
                <div class="button">
                    <a href="https://system.pifund.io/" target="_blank"
                        >Đầu Tư Ngay</a
                    >
                </div>
            </div>
            <div class="baner-img">
                <div class="img-1">
                    <img src="~@/assets/images/project/detail/1.png" alt="" />
                </div>
                <div class="img-2">
                    <img src="~@/assets/images/project/detail/2.png" alt="" />
                </div>
                <div
                    class="img-3"
                    v-if="
                        DetailProject.list && DetailProject.list.project_avata
                    "
                >
                    <img
                        :src="
                            DetailProject.base_url +
                            DetailProject.list.project_avata
                        "
                        alt=""
                    />
                </div>
                <div class="list-img">
                    <Carousel
                        :perPageCustom="[
                            [320, 2],
                            [575, 2],
                            [991, 3],
                            [1200, 3],
                            [1600, 3],
                            [2200, 3],
                            [3200, 3],
                        ]"
                        :navigationEnabled="false"
                        :navigationNextLabel="'>'"
                        :navigationPrevLabel="'<'"
                        v-if="
                            DetailProject &&
                            DetailProject.list &&
                            DetailProject.list.project_image
                        "
                    >
                        <Slide
                            class="img-item"
                            v-for="(
                                image, i
                            ) in DetailProject.list.project_image.split(',')"
                            :key="`image-${i}`"
                        >
                            <div class="box-img">
                                <img
                                    :src="DetailProject.base_url + image"
                                    alt=""
                                />
                            </div>
                        </Slide>
                    </Carousel>
                </div>
            </div>
        </b-container>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div class="tab-heading">
                        <div
                            class="tab-item"
                            :class="tabActive == 1 ? 'tab-active' : ''"
                            @click="tabActive = 1"
                        >
                            Thông tin dự án
                        </div>
                        <div
                            class="tab-item"
                            :class="tabActive == 2 ? 'tab-active' : ''"
                            @click="tabActive = 2"
                        >
                            Công Ty
                        </div>
                        <div
                            class="tab-item"
                            :class="tabActive == 3 ? 'tab-active' : ''"
                            @click="tabActive = 3"
                        >
                            Báo cáo tài chính
                        </div>
                        <div
                            class="tab-item"
                            :class="tabActive == 4 ? 'tab-active' : ''"
                            @click="tabActive = 4"
                        >
                            Hợp đồng mẫu
                        </div>
                    </div>
                    <div class="tab-content" v-if="tabActive == 1">
                        <!-- Tên dự án -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_name
                            "
                        >
                            <h3>TÊN DỰ ÁN</h3>
                            <p
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_name
                                "
                            >
                                {{
                                    DetailProject.list.project_name
                                        ? DetailProject.list.project_name
                                        : 'Dự án chưa cập nhật tên'
                                }}
                            </p>
                        </template>
                        <!-- Giới thiệu -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_introduce
                            "
                        >
                            <h3>GIỚI THIỆU</h3>
                            <template
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_introduce
                                "
                            >
                                <p
                                    v-html="
                                        DetailProject.list.project_introduce
                                    "
                                ></p
                            ></template>

                            <p v-else>Dự án chưa cập nhật giới thiệu dự án</p>
                        </template>
                        <!-- Website -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_address
                            "
                        >
                            <h3>WEBSITE</h3>
                            <p
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_address
                                "
                            >
                                {{
                                    DetailProject.list.project_website
                                        ? DetailProject.list.project_website
                                        : 'Dự án chưa cập nhật website'
                                }}
                            </p>
                        </template>
                        <!-- ĐỊA CHỈ -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_address
                            "
                        >
                            <h3>ĐỊA CHỈ</h3>
                            <p
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_address
                                "
                            >
                                {{
                                    DetailProject.list.project_address
                                        ? DetailProject.list.project_address
                                        : 'Dự án chưa cập nhật địa chỉ'
                                }}
                            </p>
                        </template>
                        <!-- Mô tả-->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_description
                            "
                        >
                            <h3>MÔ TẢ</h3>
                            <p
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_description
                                "
                                v-html="DetailProject.list.project_description"
                            ></p>
                            <p v-else>Dự án chưa cập nhật mô tả dự án</p>
                        </template>
                        <!-- Chi tiết -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_detail
                            "
                        >
                            <h3>CHI TIẾT</h3>
                            <template
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_detail
                                "
                                v-html="DetailProject.list.project_detail"
                            ></template>
                            <p v-else>Dự án chưa cập nhật thông tin chi tiết</p>
                        </template>
                        <!-- Thông tin nổi bật -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_highlight
                            "
                        >
                            <h3>THÔNG TIN NỔI BẬT</h3>
                            <p
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_highlight
                                "
                                v-html="DetailProject.list.project_highlight"
                            ></p>
                            <p v-else>Dự án chưa cập nhật thông tin nổi bật</p>
                        </template>
                        <!-- Điểm khác biệt -->
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.project_point_difference
                            "
                        >
                            <h3>ĐIỂM KHÁC BIỆT</h3>
                            <p
                                v-if="
                                    DetailProject &&
                                    DetailProject.list &&
                                    DetailProject.list.project_point_difference
                                "
                                v-html="
                                    DetailProject.list.project_point_difference
                                "
                            ></p>
                            <p v-else>
                                Dự án chưa cập nhật thông tin khác biệt
                            </p>
                        </template>
                    </div>

                    <div class="tab-content" v-if="tabActive == 2">
                        <template
                            v-if="
                                DetailProject &&
                                DetailProject.list &&
                                DetailProject.list.company
                            "
                        >
                            <h3>TÊN CÔNG TY</h3>
                            <p>
                                {{
                                    DetailProject.list.company.company_name
                                        ? DetailProject.list.company
                                              .company_name
                                        : 'Chưa có tên công ty'
                                }}
                            </p>
                            <h3>MÔ TẢ</h3>
                            <p>
                                {{
                                    DetailProject.list.company
                                        .company_description
                                        ? DetailProject.list.company
                                              .company_description
                                        : 'Chưa có mô tả'
                                }}
                            </p>
                            <h3>ĐỊA CHỈ</h3>
                            <p>
                                {{
                                    DetailProject.list.company.company_address
                                        ? DetailProject.list.company
                                              .company_address
                                        : 'Chưa có địa chỉ công ty'
                                }}
                            </p>
                            <h3>WEBSITE CÔNG TY</h3>
                            <p>
                                <a
                                    v-if="
                                        DetailProject.list.company
                                            .company_website
                                    "
                                    :href="
                                        DetailProject.list.company
                                            .company_website
                                    "
                                    target="_blank"
                                >
                                    {{
                                        DetailProject.list.company
                                            .company_website
                                    }}
                                </a>
                                <span v-else>Chưa có website công ty</span>
                            </p>
                            <template v-if="videoList">
                                <h3>VIDEO CÔNG TY</h3>
                                <carousel
                                    class="video-cn"
                                    :perPage="1"
                                    @page-change="onChangeVideo"
                                    :navigationEnabled="true"
                                    :navigationNextLabel="'>'"
                                    :navigationPrevLabel="'<'"
                                    v-if="videoList"
                                >
                                    <slide
                                        class="item-carousel"
                                        v-for="video in videoList"
                                        :key="`video-${video}`"
                                    >
                                        <div class="video-company">
                                            <div :id="`player-${video}`" />
                                        </div>
                                    </slide>
                                </carousel>
                            </template>
                            <h3>HÌNH ẢNH CÔNG TY</h3>
                            <Carousel
                                :perPageCustom="[
                                    [320, 1],
                                    [575, 2],
                                    [991, 3],
                                    [1200, 3],
                                    [1600, 3],
                                    [2200, 3],
                                    [3200, 3],
                                ]"
                                :navigationEnabled="true"
                                :navigationNextLabel="'>'"
                                :navigationPrevLabel="'<'"
                                v-if="DetailProject.list.company.company_image"
                            >
                                <Slide
                                    class="item-carousel"
                                    v-for="(
                                        image, i
                                    ) in DetailProject.list.company.company_image.split(
                                        ',',
                                    )"
                                    :key="`image-${i}`"
                                >
                                    <div class="news-item">
                                        <div class="new-img">
                                            <img
                                                :src="
                                                    DetailProject.base_url +
                                                    image
                                                "
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </Slide>
                            </Carousel>
                            <p v-else>Chưa có hình ảnh công ty</p>
                        </template>
                        <template v-else>
                            <h3>CHƯA CÓ THÔNG TIN</h3>
                        </template>
                    </div>
                    <div class="tab-content" v-if="tabActive == 3">
                        <a
                            :href="FinancialReport.url"
                            target="_blank"
                            v-if="FinancialReport && FinancialReport.url"
                        >
                            <h3 class="cursor-pointer">
                                NHẤP VÀO ĐỂ XEM BÁO CÁO TÀI CHÍNH
                            </h3>
                        </a>
                        <h3 v-else>CHƯA CÓ THÔNG TIN</h3>
                    </div>
                    <div class="tab-content" v-if="tabActive == 4">
                        <a
                            :href="ElectronicContract.url"
                            target="_blank"
                            v-if="ElectronicContract && ElectronicContract.url"
                        >
                            <h3 class="cursor-pointer">
                                NHẤP VÀO ĐỂ XEM HỢP ĐỒNG MẪU
                            </h3>
                        </a>
                        <h3 v-else>CHƯA CÓ THÔNG TIN</h3>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<style lang="scss">
.video-cn {
    .VueCarousel-dot-container {
        margin-top: 0 !important;
        button {
            margin-top: 0 !important;
        }
    }
}
.video-company {
    padding-top: 56.25%;
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
    }
}
.pif-banner {
    height: 100%;
    min-height: 100vh;
    width: 100vw !important;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 800px;
    overflow: hidden;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
    .baner-content {
        width: 100%;
        max-width: 600px;
        margin-left: 150px;
        color: #003189;
        position: relative;
        z-index: 3;
        @media (max-width: 1440px) {
            margin-left: 100px;
        }
        @media (max-width: 1299px) {
            margin-left: 50px;
        }
        @media (max-width: 991px) {
            text-align: center;
            max-width: none;
            margin-top: 20px;
            margin-left: 0px;
        }
        @media (max-width: 480px) {
            padding: 10px;
        }
        h3 {
            text-transform: uppercase;
            font-weight: 800;
            font-size: 50px;
            margin-bottom: 30px;
            @media (max-width: 1299px) {
                font-size: 40px;
            }
            @media (max-width: 480px) {
                font-size: 30px;
                margin-bottom: 20px;
            }
            @media (max-width: 375px) {
                font-size: 25px;
            }
        }
        p {
            font-weight: 500;
            font-style: italic;
            font-size: 24px;
            margin-bottom: 8px;
            @media (max-width: 1299px) {
                font-size: 20px;
            }
            @media (max-width: 480px) {
                font-size: 16px;
                margin-bottom: 5px;
            }
            a {
                font-weight: 800;
                color: #003189;
            }
            span {
                font-weight: 800;
            }
        }
        .progress-content {
            margin-top: 30px;
            width: 100%;
            max-width: 400px;
            @media (max-width: 991px) {
                margin: 30px auto;
            }
            @media (max-width: 480px) {
                margin: 20px auto;
            }
            .progress-text {
                display: flex;
                justify-content: space-between;
                p {
                    font-size: 16px;
                    margin-bottom: 0px;
                    @media (max-width: 1299px) {
                        font-size: 14px;
                    }
                }
            }
            .project-process {
                width: 100%;
                text-align: center;
                margin: 10px 0px;
                .loading-progress {
                    height: 20px;
                    width: 100%;
                    position: relative;
                    .progress-bg {
                        background: #ffffff;
                        border: 1px solid #0040ff;
                        box-sizing: border-box;
                        height: 20px;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        .active-bg {
                            content: '';
                            background-image: linear-gradient(
                                to right,
                                #363795,
                                #0040ff
                            );
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            bottom: 0;
                            margin: auto;
                            width: 10%;
                            height: 20px;
                        }
                    }
                    .progress-active {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        right: 0px;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #f4f4f4;
                        font-size: 10px;
                        @media (max-width: 991px) {
                            font-size: 12px;
                        }
                        @media (max-width: 575px) {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
        .button {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 50px 0;
            @media (max-width: 991px) {
                margin: 30px auto;
                justify-content: center;
            }
            a {
                background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
                height: 50px;
                width: 205px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #fff;
                border-radius: 20px;
                box-shadow: 2px 2px 10px 5px rgba($color: #000000, $alpha: 0.3);
                color: #ffff;
                font-weight: 600;
                text-decoration: none;
                transition: all 0.5s;
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
    .baner-img {
        @media (max-width: 991px) {
            position: relative;
            width: 100%;
        }
        .img-1 {
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            img {
                width: 90%;
                height: 100%;
            }
            @media (max-width: 991px) {
                display: none;
            }
        }
        .img-2 {
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            width: 62vw;
            @media (max-width: 1440px) {
                width: 65vw;
            }
            @media (max-width: 991px) {
                position: relative;
                width: 100%;
                height: 500px;
            }
            @media (max-width: 449px) {
                width: 1000px;
                transform: rotate(180deg);
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .img-3 {
            position: absolute;
            top: 3%;
            right: 0;
            width: 44vw;
            @media (max-width: 1440px) {
                top: 5%;
            }
            @media (max-width: 767px) {
                width: 65vw;
            }
            @media (max-width: 575px) {
                width: 320px;
            }
            @media (max-width: 449px) {
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
                border-radius: 300px 0px 0px 300px;
                border: 15px solid #fff;
            }
        }
        .list-img {
            position: absolute;
            bottom: 2%;
            right: 2%;
            width: 50vw;
            @media (max-width: 991px) {
                bottom: 2%;
                width: 70vw;
            }
            @media (max-width: 767px) {
            }
            @media (max-width: 575px) {
                right: 5%;
                bottom: 5%;
            }
            @media (max-width: 449px) {
                right: 0%;
                width: 100%;
            }
            .VueCarousel {
                .VueCarousel-navigation {
                    @media (max-width: 575px) {
                        display: none !important;
                    }
                    .VueCarousel-navigation-prev {
                        font-family: monospace;
                        color: #0930ff;
                        content: '<';
                        font-size: 60px;
                        top: 40% !important;
                        outline: none;
                        @media (max-width: 991px) {
                            font-size: 45px;
                        }
                        @media (max-width: 575px) {
                            left: 25px !important;
                        }
                    }
                    .VueCarousel-navigation-next {
                        font-family: monospace;
                        color: #0930ff;
                        content: '>';
                        font-size: 60px;
                        top: 40% !important;
                        outline: none;
                        @media (max-width: 991px) {
                            font-size: 45px;
                        }
                        @media (max-width: 575px) {
                            right: 25px !important;
                        }
                    }
                }
                .VueCarousel-wrapper {
                    .VueCarousel-inner {
                        @media (max-width: 575px) {
                        }
                        .img-item {
                            width: 100%;
                            height: 280px;
                            border-radius: 15px;
                            position: relative;
                            @media (max-width: 1399px) {
                                height: 230px;
                            }
                            @media (max-width: 991px) {
                                height: 200px;
                            }
                            .box-img {
                                top: 0;
                                right: 0px;
                                left: 0px;
                                bottom: 0px;
                                position: absolute;
                                padding: 15px;
                                @media (max-width: 1399px) {
                                    padding: 10px;
                                }
                            }
                            img {
                                object-fit: cover;
                                object-position: center;
                                width: 100%;
                                height: 100%;
                                border-radius: 15px;
                                border: 15px solid #fff;
                                @media (max-width: 1399px) {
                                    border: 10px solid #fff;
                                }
                            }
                        }
                    }
                }
                .VueCarousel-pagination {
                    .VueCarousel-dot-container {
                        margin-top: 0px !important;
                        .VueCarousel-dot {
                            background-color: #999 !important;
                            opacity: 1;
                            width: 15px !important;
                            height: 15px !important;
                            margin-top: 0px !important;
                            @media (max-width: 991px) {
                                width: 15px !important;
                                height: 15px !important;
                            }
                            @media (max-width: 575px) {
                                width: 10px !important;
                                height: 10px !important;
                            }
                            &.VueCarousel-dot--active {
                                background-color: #003189 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.tab-heading {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    font-size: 20px;
    max-width: fit-content;
    box-shadow: 0px 0px 5px #0000005a;
    border-radius: 15px;
    flex-wrap: wrap;
    overflow-x: auto;
    @media (max-width: 991px) {
        justify-content: inherit;
    }
    .tab-item {
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #363795;
        font-size: 16px;
        @media (max-width: 575px) {
            width: 150px;
            height: 40px;
        }
        &.tab-active {
            background-image: linear-gradient(to right, #363795, #0040ff);
            color: #ffffff;

            font-weight: bold;
        }
        cursor: pointer;
        border-radius: 15px;
        // &:first-child {
        //     border-top-left-radius: 15px;
        //     border-bottom-left-radius: 15px;
        // }
        // &:last-child {
        //     border-top-right-radius: 15px;
        //     border-bottom-right-radius: 15px;
        // }
    }
}
.tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
    font-size: 20px;
    box-shadow: 0px 0px 5px #0000005a;
    border-radius: 15px;
    padding: 50px;
    font-family: 'Roboto', sans-serif !important;
    @media (max-width: 991px) {
        padding: 30px;
    }
    @media (max-width: 575px) {
        padding: 10px;
    }
    h3 {
        font-size: 22px;
        color: #003189;
        font-weight: 800;
    }
    .VueCarousel {
        .VueCarousel-navigation {
            @media (max-width: 575px) {
                display: none !important;
            }
            .VueCarousel-navigation-prev {
                font-family: monospace;
                color: #0930ff;
                content: '<';
                font-size: 60px;
                top: 40% !important;
                outline: none;
                @media (max-width: 991px) {
                    font-size: 45px;
                }
                @media (max-width: 575px) {
                    left: 25px !important;
                }
            }
            .VueCarousel-navigation-next {
                font-family: monospace;
                color: #0930ff;
                content: '>';
                font-size: 60px;
                top: 40% !important;
                outline: none;
                @media (max-width: 991px) {
                    font-size: 45px;
                }
                @media (max-width: 575px) {
                    right: 25px !important;
                }
            }
        }
        .VueCarousel-wrapper {
            .VueCarousel-inner {
                @media (max-width: 575px) {
                }
                .item-carousel {
                    padding: 20px;
                    @media (max-width: 767px) {
                        padding: 10px;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 0px;
                    }
                    .news-item {
                        width: 100%;
                        height: 100%;
                        min-height: 350px;
                        color: #ffffff;
                        position: relative;
                        overflow: hidden;
                        box-shadow: 2px 2px 10px 5px rgba(204, 204, 204, 0.466);
                        z-index: 1;
                        .new-img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .new-content {
                            position: absolute;
                            bottom: 0;
                            padding: 10px;
                            h4 {
                                margin-bottom: 5px;
                                font-size: 23px;
                                position: relative;
                                line-height: 36px;
                                z-index: 2;
                                display: -webkit-box;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                @media (max-width: 1199px) {
                                    font-size: 19px;
                                }
                            }
                            p {
                                display: none;
                                margin-bottom: 5px;
                                font-size: 17px;
                                position: relative;
                                z-index: 2;
                                display: -webkit-box;
                                line-height: 25px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                font-weight: 400;
                                @media (max-width: 1199px) {
                                    font-size: 15px;
                                }
                            }
                            a {
                                float: right;
                                color: #ffffff;
                                font-style: italic;
                                text-decoration: underline;
                                font-size: 18px;
                                font-weight: bold;
                                position: relative;
                                z-index: 2;
                                @media (max-width: 1199px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .VueCarousel-pagination {
            .VueCarousel-dot-container {
                @media (max-width: 575px) {
                    margin-top: 0px !important;
                }
                @media (max-width: 375px) {
                }
                .VueCarousel-dot {
                    background-color: #999 !important;
                    opacity: 1;
                    width: 15px !important;
                    height: 15px !important;
                    @media (max-width: 991px) {
                        width: 15px !important;
                        height: 15px !important;
                    }
                    @media (max-width: 575px) {
                        width: 10px !important;
                        height: 10px !important;
                    }
                    &.VueCarousel-dot--active {
                        background-color: #003189 !important;
                    }
                }
            }
        }
    }
}
</style>
