var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 w-100 mb-5"},[_c('b-container',{staticClass:"p-0 pif-banner",attrs:{"fluid":""}},[_c('div',{staticClass:"baner-content"},[(_vm.DetailProject.list && _vm.DetailProject.list.project_name)?_c('h3',[_vm._v(" "+_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.project_name ? _vm.DetailProject.list.project_name : 'Chưa có thông tin')+" ")]):_vm._e(),(
                    _vm.DetailProject.list && _vm.DetailProject.list.project_website
                )?_c('p',[_vm._v(" Website: "),_c('a',{attrs:{"href":_vm.DetailProject.list &&
                        _vm.DetailProject.list.project_website
                            ? _vm.DetailProject.list.project_website
                            : '',"target":"_blank"}},[_vm._v(_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.project_website ? _vm.DetailProject.list.project_website : 'Chưa có thông tin'))])]):_vm._e(),(
                    _vm.DetailProject.list &&
                    _vm.DetailProject.list.inves_start_date
                )?_c('p',[_vm._v(" Ngày bắt đầu gọi vốn: "),_c('span',[_vm._v(_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.inves_start_date ? _vm.DetailProject.list.inves_start_date : 'Chưa có thông tin'))])]):_vm._e(),(
                    _vm.DetailProject.list && _vm.DetailProject.list.inves_end_date
                )?_c('p',[_vm._v(" Ngày kết thúc dự kiến: "),_c('span',[_vm._v(_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.inves_end_date ? _vm.DetailProject.list.inves_end_date : 'Chưa có thông tin'))])]):_vm._e(),(
                    _vm.DetailProject.list &&
                    _vm.DetailProject.list.project_revenue_month
                )?_c('p',[_vm._v(" Doanh thu dự kiến: "),_c('span',[_vm._v(_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.project_revenue_month ? _vm.DetailProject.list.project_revenue_month : 'Chưa có thông tin'))])]):_vm._e(),(
                    _vm.DetailProject.list &&
                    _vm.DetailProject.list.project_valuation
                )?_c('p',[_vm._v(" Định giá doanh nghiệp: "),_c('span',[_vm._v(_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.project_valuation ? parseFloat(_vm.DetailProject.list.project_valuation) .toFixed(0) .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' VND' : 'Chưa có thông tin'))])]):_vm._e(),_c('p',[_vm._v(" Số vốn gọi: "),_c('span',[_vm._v(_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.total_call_project ? parseFloat(_vm.DetailProject.list.total_call_project) .toFixed(0) .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' VND' : 'Chưa có thông tin'))])]),_c('div',{staticClass:"progress-content"},[_c('div',{staticClass:"progress-text"},[_c('p',[_vm._v("Đầu tư")]),_c('p',[_vm._v(" Kết thúc: "+_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.inves_end_date ? _vm.DetailProject.list.inves_end_date : 'Chưa có thời gian kết thúc')+" ")])]),_c('div',{staticClass:"project-process"},[_c('div',{staticClass:"loading-progress"},[_c('div',{staticClass:"progress-bg"},[(
                                    _vm.DetailProject &&
                                    _vm.DetailProject.list &&
                                    _vm.DetailProject.list
                                        .total_investment_project &&
                                    _vm.DetailProject.list.total_invested
                                )?_c('div',{staticClass:"active-bg",style:(("width:" + ((+_vm.DetailProject.list.total_invested /
                                        +_vm.DetailProject.list
                                            .total_call_project) *
                                        100 >=
                                    50
                                        ? ("calc(" + ((+_vm.DetailProject.list
                                                  .total_invested /
                                                  +_vm.DetailProject.list
                                                      .total_call_project) *
                                              100) + "%)")
                                        : '-20px')))},[_c('div',{staticClass:"progress-active"},[_vm._v(" "+_vm._s(( (+_vm.DetailProject.list .total_invested / +_vm.DetailProject.list .total_call_project) * 100 ).toFixed(2))+" % ")])]):_vm._e()])])]),_c('div',{staticClass:"progress-text"},[_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.project_count_investment ? _vm.TruncateToDecimals( _vm.DetailProject.list .project_count_investment ) : 0)+" nhà đầu tư ")]),_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.total_invested ? _vm.TruncateToDecimals( _vm.DetailProject.list.total_invested ) : 0)+" / "+_vm._s(_vm.DetailProject.list && _vm.DetailProject.list.total_call_project ? _vm.TruncateToDecimals( _vm.DetailProject.list.total_call_project ) : 0)+" ")])])]),_c('div',{staticClass:"button"},[_c('a',{attrs:{"href":"https://system.pifund.io/","target":"_blank"}},[_vm._v("Đầu Tư Ngay")])])]),_c('div',{staticClass:"baner-img"},[_c('div',{staticClass:"img-1"},[_c('img',{attrs:{"src":require("@/assets/images/project/detail/1.png"),"alt":""}})]),_c('div',{staticClass:"img-2"},[_c('img',{attrs:{"src":require("@/assets/images/project/detail/2.png"),"alt":""}})]),(
                    _vm.DetailProject.list && _vm.DetailProject.list.project_avata
                )?_c('div',{staticClass:"img-3"},[_c('img',{attrs:{"src":_vm.DetailProject.base_url +
                        _vm.DetailProject.list.project_avata,"alt":""}})]):_vm._e(),_c('div',{staticClass:"list-img"},[(
                        _vm.DetailProject &&
                        _vm.DetailProject.list &&
                        _vm.DetailProject.list.project_image
                    )?_c('Carousel',{attrs:{"perPageCustom":[
                        [320, 2],
                        [575, 2],
                        [991, 3],
                        [1200, 3],
                        [1600, 3],
                        [2200, 3],
                        [3200, 3] ],"navigationEnabled":false,"navigationNextLabel":'>',"navigationPrevLabel":'<'}},_vm._l((_vm.DetailProject.list.project_image.split(',')),function(image,i){return _c('Slide',{key:("image-" + i),staticClass:"img-item"},[_c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":_vm.DetailProject.base_url + image,"alt":""}})])])}),1):_vm._e()],1)])]),_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"tab-heading"},[_c('div',{staticClass:"tab-item",class:_vm.tabActive == 1 ? 'tab-active' : '',on:{"click":function($event){_vm.tabActive = 1}}},[_vm._v(" Thông tin dự án ")]),_c('div',{staticClass:"tab-item",class:_vm.tabActive == 2 ? 'tab-active' : '',on:{"click":function($event){_vm.tabActive = 2}}},[_vm._v(" Công Ty ")]),_c('div',{staticClass:"tab-item",class:_vm.tabActive == 3 ? 'tab-active' : '',on:{"click":function($event){_vm.tabActive = 3}}},[_vm._v(" Báo cáo tài chính ")]),_c('div',{staticClass:"tab-item",class:_vm.tabActive == 4 ? 'tab-active' : '',on:{"click":function($event){_vm.tabActive = 4}}},[_vm._v(" Hợp đồng mẫu ")])]),(_vm.tabActive == 1)?_c('div',{staticClass:"tab-content"},[(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_name
                        )?[_c('h3',[_vm._v("TÊN DỰ ÁN")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_name
                            )?_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list.project_name ? _vm.DetailProject.list.project_name : 'Dự án chưa cập nhật tên')+" ")]):_vm._e()]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_introduce
                        )?[_c('h3',[_vm._v("GIỚI THIỆU")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_introduce
                            )?[_c('p',{domProps:{"innerHTML":_vm._s(
                                    _vm.DetailProject.list.project_introduce
                                )}})]:_c('p',[_vm._v("Dự án chưa cập nhật giới thiệu dự án")])]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_address
                        )?[_c('h3',[_vm._v("WEBSITE")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_address
                            )?_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list.project_website ? _vm.DetailProject.list.project_website : 'Dự án chưa cập nhật website')+" ")]):_vm._e()]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_address
                        )?[_c('h3',[_vm._v("ĐỊA CHỈ")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_address
                            )?_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list.project_address ? _vm.DetailProject.list.project_address : 'Dự án chưa cập nhật địa chỉ')+" ")]):_vm._e()]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_description
                        )?[_c('h3',[_vm._v("MÔ TẢ")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_description
                            )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.DetailProject.list.project_description)}}):_c('p',[_vm._v("Dự án chưa cập nhật mô tả dự án")])]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_detail
                        )?[_c('h3',[_vm._v("CHI TIẾT")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_detail
                            )?void 0:_c('p',[_vm._v("Dự án chưa cập nhật thông tin chi tiết")])]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_highlight
                        )?[_c('h3',[_vm._v("THÔNG TIN NỔI BẬT")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_highlight
                            )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.DetailProject.list.project_highlight)}}):_c('p',[_vm._v("Dự án chưa cập nhật thông tin nổi bật")])]:_vm._e(),(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.project_point_difference
                        )?[_c('h3',[_vm._v("ĐIỂM KHÁC BIỆT")]),(
                                _vm.DetailProject &&
                                _vm.DetailProject.list &&
                                _vm.DetailProject.list.project_point_difference
                            )?_c('p',{domProps:{"innerHTML":_vm._s(
                                _vm.DetailProject.list.project_point_difference
                            )}}):_c('p',[_vm._v(" Dự án chưa cập nhật thông tin khác biệt ")])]:_vm._e()],2):_vm._e(),(_vm.tabActive == 2)?_c('div',{staticClass:"tab-content"},[(
                            _vm.DetailProject &&
                            _vm.DetailProject.list &&
                            _vm.DetailProject.list.company
                        )?[_c('h3',[_vm._v("TÊN CÔNG TY")]),_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list.company.company_name ? _vm.DetailProject.list.company .company_name : 'Chưa có tên công ty')+" ")]),_c('h3',[_vm._v("MÔ TẢ")]),_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list.company .company_description ? _vm.DetailProject.list.company .company_description : 'Chưa có mô tả')+" ")]),_c('h3',[_vm._v("ĐỊA CHỈ")]),_c('p',[_vm._v(" "+_vm._s(_vm.DetailProject.list.company.company_address ? _vm.DetailProject.list.company .company_address : 'Chưa có địa chỉ công ty')+" ")]),_c('h3',[_vm._v("WEBSITE CÔNG TY")]),_c('p',[(
                                    _vm.DetailProject.list.company
                                        .company_website
                                )?_c('a',{attrs:{"href":_vm.DetailProject.list.company
                                        .company_website,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.DetailProject.list.company .company_website)+" ")]):_c('span',[_vm._v("Chưa có website công ty")])]),(_vm.videoList)?[_c('h3',[_vm._v("VIDEO CÔNG TY")]),(_vm.videoList)?_c('carousel',{staticClass:"video-cn",attrs:{"perPage":1,"navigationEnabled":true,"navigationNextLabel":'>',"navigationPrevLabel":'<'},on:{"page-change":_vm.onChangeVideo}},_vm._l((_vm.videoList),function(video){return _c('slide',{key:("video-" + video),staticClass:"item-carousel"},[_c('div',{staticClass:"video-company"},[_c('div',{attrs:{"id":("player-" + video)}})])])}),1):_vm._e()]:_vm._e(),_c('h3',[_vm._v("HÌNH ẢNH CÔNG TY")]),(_vm.DetailProject.list.company.company_image)?_c('Carousel',{attrs:{"perPageCustom":[
                                [320, 1],
                                [575, 2],
                                [991, 3],
                                [1200, 3],
                                [1600, 3],
                                [2200, 3],
                                [3200, 3] ],"navigationEnabled":true,"navigationNextLabel":'>',"navigationPrevLabel":'<'}},_vm._l((_vm.DetailProject.list.company.company_image.split(
                                    ','
                                )),function(image,i){return _c('Slide',{key:("image-" + i),staticClass:"item-carousel"},[_c('div',{staticClass:"news-item"},[_c('div',{staticClass:"new-img"},[_c('img',{attrs:{"src":_vm.DetailProject.base_url +
                                                image,"alt":""}})])])])}),1):_c('p',[_vm._v("Chưa có hình ảnh công ty")])]:[_c('h3',[_vm._v("CHƯA CÓ THÔNG TIN")])]],2):_vm._e(),(_vm.tabActive == 3)?_c('div',{staticClass:"tab-content"},[(_vm.FinancialReport && _vm.FinancialReport.url)?_c('a',{attrs:{"href":_vm.FinancialReport.url,"target":"_blank"}},[_c('h3',{staticClass:"cursor-pointer"},[_vm._v(" NHẤP VÀO ĐỂ XEM BÁO CÁO TÀI CHÍNH ")])]):_c('h3',[_vm._v("CHƯA CÓ THÔNG TIN")])]):_vm._e(),(_vm.tabActive == 4)?_c('div',{staticClass:"tab-content"},[(_vm.ElectronicContract && _vm.ElectronicContract.url)?_c('a',{attrs:{"href":_vm.ElectronicContract.url,"target":"_blank"}},[_c('h3',{staticClass:"cursor-pointer"},[_vm._v(" NHẤP VÀO ĐỂ XEM HỢP ĐỒNG MẪU ")])]):_c('h3',[_vm._v("CHƯA CÓ THÔNG TIN")])]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }